import React, { Component } from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/blockGenerator"
// eslint-disable-line
import { CoreBlockFragment } from "@/components/CoreBlock" // eslint-disable-line

class Post extends Component {
  render() {
    this.props.pageContext.pageTitle = this.props.data.wpCaseStudy.Meta.title
      ? this.props.data.wpCaseStudy.Meta.title
      : this.props.data.wpCaseStudy.title
    this.props.pageContext.acfFields =
      this.props.data.wpCaseStudy.BranchInformation
    this.props.pageContext.categories =
      this.props.data.wpCaseStudy.categories.nodes
    const blocks = buildBlocks(
      this.props.data.wpCaseStudy.blocks,
      {},
      this.props.pageContext
    )

    if (this.props.data.wpCaseStudy.Meta.ogImage === null) {
      this.props.data.wpCaseStudy.Meta.ogImage =
        this.props.data.wpCaseStudy.featuredImage?.node
    }

    if (this.props.data.wpCaseStudy.Meta.twitterImage === null) {
      this.props.data.wpCaseStudy.Meta.twitterImage =
        this.props.data.wpCaseStudy.featuredImage?.node
    }

    return (
      <Layout
        meta={this.props.data.wpCaseStudy.Meta}
        path={this.props.pageContext.pagePath}
        title={this.props.data.wpCaseStudy.title}
        themeOptions={this.props.data.wp.themeOptions}
      >
        {blocks}
      </Layout>
    )
  }
}

export const query = graphql`
  query ($id: String) {
    wpCaseStudy(id: { eq: $id }) {
      id
      slug
      title
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          localFile {
            publicURL
          }
        }
        ogImage {
          localFile {
            publicURL
          }
        }
      }
      categories {
        nodes {
          slug
          name
          uri
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Post
